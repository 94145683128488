.checkout-container {
    margin: auto;
    text-align: left;
    margin-bottom: 20px;

    @media only screen and (max-width: $breakpoint-m) {
        max-width: 100% !important;
        padding: 0px 20px 0px 20px;
    }

    
}

.checkout-input {
    margin: 20px 0px 20px 0px;
}

.subscriber-details {

    flex-basis: 100%;

    .subscriber-tip {
        color: #555;
        margin-top: -15px;
        margin-bottom: 20px;
    }
}

.checkout-actions {
    margin-top: 20px;
    margin-bottom: 20px;

    &.no-margin {
      .submit  {
        @media only screen and (min-width: $breakpoint-m) {
          margin-left: 0px !important;
        }
      }
    }

    .icon-left {
      min-width: 1.5rem;
    }

    .icon-right {
      min-width: 1.5rem;
    }

    .submit {
        width: 100%;
        flex-basis: 100%;

        @media only screen and (min-width: $breakpoint-m) {
            margin-left: 20px;
            width: auto;
        }
    }

    .centered-link {
        text-align: center;
        font-weight: 600;
        a {
            color: $blue;
        }
        
    }

    .cancel {
        width: 100%;
        margin-top: 20px;
        flex-basis: 100%;

        @media only screen and (min-width: $breakpoint-m) {
           margin-top: 0px;
           width: auto;
           white-space: nowrap;
        }
    }

    .accept-conditions {
        margin: auto;
        display: block;
        margin-top: 20px; 
        margin-bottom: 20px;
    }

    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 28px;
      }
      
      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
      }
      
      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: $blue;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    
}

.payment_methods {
  background-color: $light_grey;
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    display: inline-block;

    @media only screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.payment_method {
  margin: 0.5rem;
  background-color: white;
  padding: 1.5rem;
  cursor: pointer;
  text-align: center;
  width: auto;
  display: inline-block;

  @media only screen and (min-width: $breakpoint-m) {
    width: auto;
  }

  @media only screen and (max-width: 576px) {
    width: calc(100% - 4rem);
  }

  @media only screen and (min-width: 577px) {
    margin-top: 1rem;

    &:nth-child(odd) {
      margin-left: 1rem;
    }
  
    &:nth-child(even) {
      margin-left: 0.5rem;
    }
  
    &:nth-child(n + 2) {
      margin-top: 0.5rem;
    }
  }

  &.selected{
    outline: 3px solid $blue;
  }

  &:hover, &:focus, &:active{
    outline: 3px solid $blue;
  }
}

.payment_method:last-of-type {
  border-bottom-width: 0;
}

.radio_label {
  font-weight: 600;
  justify-self: start;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.payment_method_img {
  object-fit: cover;
  max-height: 40px;

}

.success {
    .payment-details {
        table {
            width: 100%;
    
            td {
                &.right {
                    text-align: right;
                    //padding-right: 20px;
                }
            }
        }
    }

    .checkout-actions {
        button {
            margin-left: 0px;
        }
    }
    
    .download-receipt {
        width: 100%;
        margin: 20px 0px 20px 0px;
        color: #000;
    }

    .desktop-flex {
        justify-content: start;
        div {
            flex-basis: 100%;
        }
    }
}


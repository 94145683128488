.steps {
    margin: 0px 0px 20px 0px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $breakpoint-m) {
        margin: 0px;
        display: block;
    }

    .status-label {
    }
    
    .steps-container {
        width: 400px;
        margin-bottom: 40px;
        margin-top: 40px;

        @media only screen and (max-width: $breakpoint-m) {
            width: 100%;
        }
    
        .step-container {
            display: inline-block;
            width: 25%;
            background-color: #ccc;
            height: 2px;
    
            &.active {
                background-color: $blue;
            }
    
            
            .step {
                border: 2px solid #ccc;
                padding: 5px;
                border-radius: 50%;
                margin: auto;
                width: 20px;
                height: 20px;
                text-align: center;
                z-index: 1;
                background: white;
                margin-top: -15px;
                font-weight: 700;
                line-height: 20px;
    
                &.active {
                    border: 2px solid $blue;
                    color: $blue;
                }
    
                &.done {
                    background: $blue;
                    border: 2px solid $blue;
    
                    &::after {
                        content: "\2713";
                        color: white;
                    }
                    
                }
                &#step-1 {
                    margin-left: 0px;
                }
    
                &#step-2 {
                    margin-left: 25%;
                }
    
                &#step-3 {
                    margin-right: 25%;
                }
    
                &#step-4 {
                    margin-right: 0px;
                }
            }
        }
    }
}

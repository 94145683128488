.product-list {

    flex-basis: 100%;

    @media only screen and (max-width: $breakpoint-m) {
        padding: 0px;
    }

    .product-list-items {
        padding-bottom: 0px;

        .product-label {
            margin-top: 0px;  
        }

        div {
            &:not(:first-child) {
                .product-label {
                    margin-top: 32px;  
                }
            }
        }
    }

    .product-row {
        
        @media only screen and (max-width: $breakpoint-m) {
            &.without-meta {
                padding-bottom: 24px;
                border-bottom: 1px solid #ccc;
                margin-bottom: 24px;
            }
        }

        @media only screen and (min-width: $breakpoint-m) {
            &:last-child {
                margin-bottom: 24px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &:not(:first-child) {
            @media only screen and (min-width: $breakpoint-m) {
                margin-top: 0px;
            }
        }

        .original-price {
            color: rgb(153, 152, 152);
            padding-right: 8px;
            text-decoration: line-through;
        }

        table {
            //margin: 20px 0px 20px 0px;
            width: 100%;
            font-weight: 700;
            padding: 0px;
    
            @media only screen and (min-width: $breakpoint-m) {
                margin: 0px;
            }
    
            tr > td:last-of-type {
                text-align: right;
                //padding-right: 20px;
            }
    
            td {
                vertical-align: top;
                padding: 0px;
                
            }
        }
        .meta {
            background: #fafafa;

            @media only screen and (min-width: $breakpoint-m) {
                background: transparent;
            }

            margin-left: -20px;
            width: calc(100% + 40px);
    
            .meta-label {
                font-weight: 500;
                padding: 20px 20px 0px 20px;
            }
            .meta-value {
                font-weight: 400;
                padding: 0px 20px 0px 20px;
            }

            &:last-of-type {
                padding-bottom: 20px;

                @media only screen and (min-width: $breakpoint-m) {
                    padding-bottom: 0px;
                }
            }
        }
    }
    


    .product-summary {
        background: #eee;
        padding: 20px;
        width: calc(100% - 40px);

        @media only screen and (max-width: $breakpoint-m) {
            width: calc(100%);
            margin-left: -20px;
        }
        
        .cart-total {
            float: right;
            font-weight: 700;

            &.normal {
                font-weight: 400;
            }
        }
    }
}
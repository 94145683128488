a.footer-support {
  text-decoration: none !important;
}

div.footer-support-div {
  display: flex;
  flex-direction: column;
}

span.footer-support-header {
  flex:1 0 50%;
}

span.footer-support-text {
  flex:1 0 50%;
  font-weight: normal !important;
  &.a{
    text-decoration: underline !important;
  }
}

span.footer-support-text > a{

}

.checkout-footer {

  a {
    text-decoration: underline;
  }
}
